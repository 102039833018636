<template>
	<div>
		<v-sheet class="dense-inputs">
			<v-row no-gutters>
				<v-col cols="5" lg="5" md="12" xs="12" class="px-1 pb-0 pt-3">
					<v-row no-gutters>
						<v-col cols="5" lg="5" md="3" xs="3">
							<v-select
								:items="searchFields"
								:placeholder="$t('message.searchColumn')"
								autocomplete="off"
								clearable
								prepend-icon="search"
								dense
								hide-details
								solo
								v-model="searchField"
                                @click:clear="resetSearch"
							>
							</v-select>
						</v-col>
						<v-col cols="4" lg="4" md="3" xs="3" class="pl-2">
							<v-text-field
								:placeholder="$t('message.searchTerm')"
								autocomplete="off"
								class="force-text-left"
								clearable
								dense
								hide-details
								solo
								v-model="searchTerm"
								@keyup.enter="searchSdas"
                                @click:clear="resetSearch"
							>
							</v-text-field>
						</v-col>
						<v-col cols="3" lg="3" md="3" xs="3" class="pl-2">
							<v-row no-gutters>
								<v-btn
									:loading="loading.search"
									class="col-6"
									@click="searchSdas"
								>{{ $t('message.search') }}</v-btn>
								<v-btn
									:loading="loading.reset"
									class="col-6"
									@click="resetSearch"
								>{{ $t('message.reset') }}</v-btn>
							</v-row>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-sheet>
		<v-overlay
			:value="loading.sdas"
			absolute
			opacity="0.15"
		>
			<v-row>
				<v-col class="text-center">
					<v-progress-circular
						color="primary"
						indeterminate
						size="40"
						width="6"
					></v-progress-circular>
				</v-col>
			</v-row>
		</v-overlay>
		<v-data-table
			:footer-props="{
				itemsPerPageOptions: [10,25,50,-1],
				showFirstLastPage: true
			}"
			:headers="headers"
            :hide-default-header="hideDefaultHeader"
            :hide-default-footer="hideDefaultFooter"
			:items="Sdas"
			:options.sync="tableOptions"
			:server-items-length="totalSdas"
			calculate-widths
			class="mt-3 appic-table-light specification-table"
			dense
			id="shippingInstructionsTable"
			item-key="Sda.id"
		>
<!--			<template v-slot:header="{ props: { headers } }">-->
<!--				<tr>-->
<!--					<th class="grey lighten-3 py-1 pl-4 text-left">{{ headers[0].text }}</th>-->
<!--					<th class="grey lighten-3 py-1 pl-4 text-left">{{ headers[1].text }}</th>-->
<!--					<th class="grey lighten-3 py-1 pl-4 text-left">{{ headers[2].text }}</th>-->
<!--					<th class="grey lighten-3 py-1 pl-4 text-left width-20-pct">{{ headers[3].text }}</th>-->
<!--                    <th class="grey lighten-3 py-1 pl-4 text-left width-20-pct">{{ headers[4].text }}</th>-->
<!--					<th class="grey lighten-3 py-1 pl-4 text-left width-15-pct">{{ headers[5].text }}</th>-->
<!--					<th class="grey lighten-3 pa-1 width-1-pct">{{ headers[6].text }}</th>-->
<!--				</tr>-->
<!--			</template>-->
            <template v-slot:item.Sda.title="{ item }">
                <span class="text-no-wrap font-weight-bold">{{ item.Sda.title }}</span>
            </template>
			<template v-slot:item.Sda.sdadate="{ item }">
				{{ item.Sda.sdadate != '0000-00-00' ? formatDate(item.Sda.sdadate) : '' }}
			</template>
			<template v-slot:item.Sda.sdasent_date="{ item }">
				{{ item.Sda.sdasent_date != '0000-00-00' ? formatDate(item.Sda.sdasent_date) : '' }}
			</template>
            <template v-slot:item.Sda.Customer.otsname="{ item }">
                {{ item.Sda.Customer.otsname ? item.Sda.Customer.otsname : item.Sda.Customer.title }}
            </template>
            <template v-slot:item.Sda.Supplier.otsname="{ item }">
				{{ item.Sda.Supplier.otsname }}
			</template>
            <template v-slot:item.Sda.Destinationport.title="{ item }">
                {{ item.Sda.Destinationport.title + ' - ' + item.Sda.Destination.name }}
            </template>
			<template v-slot:item.Sda.id="{ item }">
				<v-menu>
					<template v-slot:activator="{ on: menu }">
						<v-tooltip bottom>
							<template v-slot:activator="{ on: tooltip }">
								<v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
									<v-icon>more_vert</v-icon>
								</v-btn>
							</template>
							<span>{{ $t('message.moreActions') }}</span>
						</v-tooltip>
					</template>
					<v-list dense>
						<v-list-item class="font-sm" @click="updateSda(item.Sda.id)"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateSda') }}</v-list-item>
						<v-list-item class="font-sm" @click="cancelSda(item.Sda.id, item.Sda.title)"><v-icon small class="mr-1" color="red lighten-1">cancel</v-icon>{{ $t('message.cancelSda') }}</v-list-item>
						<v-list-item class="font-sm" @click="viewPdf(item.Sda.id)"><v-icon color="red lighten-1" small class="mr-2">fas fa-file-pdf</v-icon>{{ $t('message.pdf') }}</v-list-item>
					</v-list>
				</v-menu>
			</template>
		</v-data-table>
		<SimpleAlert
			:alert_message.sync="dialogs.error_message"
			:dialog.sync="dialogs.error"
			@dialog-closed="dialogClosed"
		></SimpleAlert>
	</div>
</template>

<script>
	import {formatDate} from "Helpers/helpers";
	// import SimpleAlert from "./SimpleAlert";
	import {numberFormat} from "../../helpers/helpers";
	import {mapGetters, mapActions} from "vuex";
	import {mapFields, mapMultiRowFields} from "vuex-map-fields";
    import { v4 as uuidv4 } from 'uuid';

    const SimpleAlert = () => import("./SimpleAlert");

	export default {
		name: "ShippingInstructionsListing",
		components: {SimpleAlert},
		data() {
			return {
				dialogs: {
					error:  false,
					error_message: ""
				},
				filterOptions: {
					status: {
						Status: {
							id: 1,
							title: 'open'
						}
					}
				},
                hideDefaultHeader: false,
                hideDefaultFooter: false,
				loading: {
					filter: {
						buyers: false
					},
					search: false,
					sdas: false
				},
				searchField: 'Sda.title',
				searchTerm: null,
				tableOptions: {},
			}
		},
		computed: {
			...mapMultiRowFields('sda',{
				Sdas: 'current.Sdas'
			}),
            ...mapFields('runtime',{
                openedTabs: 'openedTabs'
            }),
			...mapFields('sda',{
				totalSdas: 'current.totalSdas'
			}),
			...mapGetters([
				'currencies'
			]),
			formatDate: () => formatDate,
			headers () {
				let headers = [
                    {
                        id: 0,
                        text: this.$t('message.actions'),
                        value: 'Sda.id',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left width-1-pct',
                        searchable: false,
                        sortable: false
                    },
				    {
						id: 1,
						text: this.$t('message.forPO'),
						value: 'Sda.title',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
						searchable: true,
                        sortable: false
					},
					{
						id: 2,
						text: this.$t('message.siDate'),
						value: 'Sda.sdadate',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: false,
                        sortable: false
					},
                    {
                        id: 3,
                        text: this.$t('message.dateSent'),
                        value: 'Sda.sdasent_date',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: false,
                        sortable: false
                    },
                    {
                        id: 4,
                        text: this.$t('message.buyer'),
                        value: 'Sda.Customer.otsname',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: true,
                        sortable: false
                    },
                    {
                        id: 5,
                        text: this.$t('message.supplier'),
                        value: 'Sda.Supplier.otsname',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: true,
                        sortable: false
                    },
                    {
                        id: 6,
                        text: this.$t('message.destinationPort'),
                        value: 'Sda.Destinationport.title',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: true,
                        sortable: false
                    }
				]
				return headers
			},
			searchFields() {
				return this.headers.filter((header)=>header.searchable === true)
			},
		},
		methods: {
			...mapActions('sda',{
				cancelSdaById: 'cancelSdaById',
				getAllSdas: 'getAllSdas',
				searchAllSdas: 'searchAllSdas'
			}),
			async cancelSda (val, title) {
				if(await this.$root.$confirm(this.$t('message.cancelSda') + ' ' + (title != null ? title : ''), this.$t('message.confirmations.continueSdaCancelAction'), {color: 'orange'})){
					this.cancelSdaById(val).then((status) => {
						if(status == 'done'){
							this.$toast.success(this.$t('message.successes.sdaDeleted'),
								{
                                    classes: ['icon-float-left'],
									icon: 'check_circle_outline'
								}
							)
							this.loadSdas()
						} else {
							this.$toast.error(this.$t('message.errors.sdaNotDeleted'),
								{
                                    classes: ['icon-float-left'],
									icon: 'error_outline'
								}
							)
						}
					})
					.catch(()=>{
						this.$toast.error(this.$t('message.errors.sdaNotDeleted'),
							{
                                classes: ['icon-float-left'],
								icon: 'error_outline'
							}
						)
					})
					.finally(()=>{

					})
				}
			},
			dialogClosed () {
				this.dialogs.error = false;
				this.dialogs.error_message = ''
			},
			formatThisNumber(value,format){
				return numberFormat(value,format)
			},
			loadSdas () {
				this.loading.sdas = true;
				let payload = {
					tableOptions: this.tableOptions,
					filterOptions: this.filterOptions
				}
				this.getAllSdas(payload)
					.then(()=>{
						this.loading.sdas = false;
					})
					.catch(()=>{
						this.loading.sdas = false;
					})
					.finally(()=>{
						this.loading.sdas = false;
					})
			},
			resetSearch () {
				this.hideDefaultFooter = false
				this.loading.lcas = false
				this.searchField = 'Sda.title'; //default search field
				this.searchTerm = null;
				this.tableOptions.itemsPerPage = 10
				this.filterOptions = {};
			},
			searchSdas () {
				if(this.searchField == null || this.searchTerm == null){
					this.dialogs.error = true
					this.dialogs.error_message = this.$t('message.errors.noSearchColumnAndSearchTerm')
				} else {
                    //TODO search sdas
                    this.filterOptions = {}
                    this.hideDefaultFooter = true
                    this.loading.sdas = true;
                    this.loading.search = true;
                    let payload = {
                        tableOptions: this.tableOptions,
                        search: {
                            field: this.searchField,
                            value: this.searchTerm
                        }
                    }
                    this.searchAllSdas(payload)
                        .then(() => {
                            this.loading.search = false;
                            this.loading.sdas = false;
                        })
                        .catch(()=>{
                            this.loading.search = false;
                            this.loading.sdas = false;
                        })
                        .finally(()=>{
                            this.loading.search = false;
                            this.loading.sdas = false;
                        })
				}
			},
			updateSda (val) {
                let tab = window.open('/v1/sdas/update/' + val,'_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
                // router.push({name: 'update_lca', params: { lcaId : val}}).catch(err => {})
			},
			viewPdf (sdaId) {
                let document = encodeURIComponent('SI ' + this.Sdas.find( sda => sda.Sda.id == sdaId )?.Sda.title)
				let user = JSON.parse(localStorage.getItem('user'))
                let tab = window.open(
					process.env.VUE_APP_PDF_SERVER
                    + '/api/render/'
                    + document
                    + '?url='
					+ process.env.VUE_APP_BASE_URL
					+ '/v1/sdas/print/'
					+ sdaId
					+ '/'
					+ uuidv4()
					+ '&waitFor=h1&pdf.margin.top=15mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
					, "_blank");
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
			},
		},
		watch: {
			filterOptions: {
				handler(){
					if(!this.loading.search) {
						this.loadSdas()
					}
				},
				deep: true
			},
			tableOptions: {
				handler(){
					this.loadSdas()
				},
				deep: true
			},
		},
		created(){
			let dt = new Date()
			let defaultYear = dt.getFullYear() - 1
			this.loadSdas()
		}
	}
</script>

<style>
.font-xs {
    font-size: 0.60rem;
}
.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.truncate {
    display: inline-block;
    width: 100px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
}
</style>